<template>
  <div class="widget-box" v-if="relatedArticles">
    <header class="widget-heading">
        <h2>{{ $t("Related Articles") }}</h2>
    </header>
            <div class="swiper-container related-newss related-news-slider0 swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events swiper-container-free-mode swiper-container-multirow">
        <div class="swiper-head">
            <ul class="filter-post">

                                                <li v-for="article in relatedArticles" @click="doActive(article[0].tournament.name)" :class="{active: (article[0].tournament.name == aTab)? true: false }"><a href="javascript:;">{{article[0].competition.competition_name}}</a></li>
<!--                                                <li class=""><a href="javascript:changeRelatedNewsTab(1)">Champions League</a></li>-->
                                            </ul>
            <div class="swiper-button-next swiper-button-disabled" tabindex="-1" role="button" aria-label="Next slide" aria-controls="swiper-wrapper-4a8b0179d8485133" aria-disabled="true"></div>
            <div class="swiper-button-prev swiper-button-disabled" tabindex="-1" role="button" aria-label="Previous slide" aria-controls="swiper-wrapper-4a8b0179d8485133" aria-disabled="true"></div>
        </div>
        <div class="swiper-wrapper" v-for="articles in relatedArticles" id="swiper-wrapper-4a8b0179d8485133" aria-live="polite" style="width: 1188px; transform: translate3d(0px, 0px, 0px);">
          <div v-for="article in articles">
                        <div class="swiper-slide key-post swiper-slide-active" role="group" aria-label="1 / 1" style="width: 287px; margin-right: 10px;">
                <div class="img-holder">
                      <router-link :to="{ name: 'sport_news_detail', params: { sport: $getMatchedSportsName(article.sport_id),title:article.slug}}">
                        <img :src="$resizeImage(article.image.image_path,245,150)" width="245" height="150" :alt="article.image_caption">
                      </router-link>
                </div>
                <h3>
                  <router-link :to="{ name: 'sport_news_detail', params: { sport: $getMatchedSportsName(article.sport_id),title:article.slug}}">
                  {{article.headline}}</router-link></h3>
                <ul class="meta2">
                    <li><span class="badge badge-green">{{article.tournament.name}}</span></li>
                    <li><time class="date" :datetime="article.start_date  | formatDate2('DD MM YYYY')">{{ article.start_date  | formatDate2('DD MM YYYY')}}</time></li>
                </ul>
            </div>
                    </div>
        </div>
    <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
        </div>
</template>

<script>

export default {
  name: 'RelatedArticle',
  props: ['relatedArticles'],
    data() {
        return {
          isActive : '',
          aTab: '',
        }
    },

    mounted() {
    },

    methods: {
      doActive(key) {
        this.aTab = key;
      },

    }
}

</script>
