<template>
    <div class="text-center" v-if="!is_data_ready">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div v-else-if="is_data_ready">
        <!--  Content Start -->
        <section class="sabc_article_detail_area">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <!-- article detail head area start -->
                        <div class="sabc_article_detail_head" v-if="sportNewsDetail">
                            <h1>
                                {{sportNewsDetail.headline}}
                            </h1>
                            <ul>
                                <li>
                                    <p>
                                        <img :alt="sportNewsDetail.admin.display_name" :src="$resizeImage(sportNewsDetail.admin.image.image_path, 36, 36)" width="36" height="36" v-if="sportNewsDetail.admin.image"/>
                                        By
                                        <span v-if="sportNewsDetail.admin.is_publish">
                                            <router-link :to="{name:'author', params: {slug:sportNewsDetail.admin.slug}}">
                                                {{sportNewsDetail.admin.display_name}}
                                            </router-link >
                                        </span>
                                        <span v-else>
                                            {{sportNewsDetail.admin.display_name}}
                                        </span>
                                    </p>
                                </li>
                                <li>
                                    <p>{{this.sportNewsDetail.start_date | formatDate('Do MMMM YYYY')}}</p>
                                </li>
                            </ul>
                            <span>
                            <!-- <img :alt="sportNewsDetail.headline" :src="$resizeImage(sportNewsDetail.image.image_path, 1232, 693)" height="693" width="1232" loading="lazy"/> -->
                            <picture>
                                <source media="(min-width: 991px)" :srcset="$resizeImage(sportNewsDetail.image.image_path,  1206, 678)">
                                <source media="(min-width: 768px)" :srcset="$resizeImage(sportNewsDetail.image.image_path, 758, 426)">
                                <img :src="$resizeImage(sportNewsDetail.image.image_path, 572, 322)" width="1206" height="678" :alt="sportNewsDetail.headline">
                            </picture>
                         </span>
                        </div>

                        <!-- article detail head area end -->
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="container">
                <div class="row sabc_art_dtl">
                    <div class="col-12 col-xl-8 col-lg-8 col-md-12 col-sm-12">
                        <div class="sabc_article_dtl_desc" v-if="sportNewsDetail">
                            <h2 v-html="sportNewsDetail.stand_first"></h2>
                            <div v-html="sportNewsDetail.description"></div>
                        </div>
                        <div class="sabc_tier_news_tabs" v-if="sportNewsDetail && sportNewsDetail.subjects.length">
                            <ul>
                                <li v-for="subject in sportNewsDetail.subjects" :key="subject.id">
                                    <router-link :to="{name:'sport_tag_news', params: {sport:sportNewsDetail.sport.slug ,slug: subject.slug, page: 1}}">
                                        {{subject.name}}
                                    </router-link >
                                </li>
                            </ul>
                        </div>
                        <section class="sabc_news_section" v-if="relatedArticles">
                            <div class="container"><h2>Related News<i></i></h2>
                                <div class="row">
                                    <div class="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12" v-for="article in relatedArticles" :key="article.id">
                                        <div class="sabc_news_box">
                                            <router-link :to="{name: 'news_detail', params: {sport:article.sport.slug , news: 'news' ,slug: article.slug}}" class="sabc_news_pic">
                                                <!-- <img :alt="article.image.file_name" :src="$resizeImage(article.image.image_path, 181, 188)" loading="lazy" /> -->
                                                <picture>
                                                    <source media="(min-width: 991px)" :srcset="$resizeImage(article.image.image_path, 181, 188)">
                                                    <img :src="$resizeImage(article.image.image_path, 144, 91)" width="181" height="188" :alt="article.image.file_name" loading="lazy">
                                                </picture>
                                            </router-link>
                                            <div class="sabc_news_desc">
                                                <h3>
                                                    <router-link :to="{name: 'news_detail', params: {sport:article.sport.slug , news: 'news' ,slug: article.slug}}">
                                                        {{article.headline}}
                                                    </router-link>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <SideBar :editorsPick="editorsPick"></SideBar>
                </div>
            </div>
        </section>
    </div>
    <!--  Content end -->
</template>

<script>

    import EditorPicks from '@/components/common/editor-pick';
    import RelatedArticle from '@/components/common/related-article';
    import SideBar from '@/components/common/sidebar/sidebar';
    import moment from 'moment';

    export default {
        name: 'NewsDetail',
        data() {
            return {
                sportNewsDetail: null,
                relatedArticles: [],
                editorsPick: [],
            }
        },
        components: {
            EditorPicks, RelatedArticle, SideBar
        },
        mounted() {
            if(this.$route.params.sport == 'football'){
                return this.$router.push({ name: 'news_detail', params: {sport:'soccer', news: 'news', 'slug':this.$route.params.slug } })
            }
            this.getNewsDetail();
            var scripts = [
                "https://platform.twitter.com/widgets.js",
                "https://www.instagram.com/embed.js"
            ];
            scripts.forEach(script => {
                let tag = document.createElement("script");
                tag.setAttribute("src", script);
                tag.defer = true;
                document.head.appendChild(tag);
            });
        },
        serverPrefetch() {
            return this.getNewsDetail();
        },
        computed: {
            keywords() {
                return this.$getSafe(() => this.sportNewsDetail.subjects.map((subj) => subj.name).join(','))
            }
        },
        watch: {
            $route(to, from) {
                this.getNewsDetail();
            }
        },
        jsonld() {
            if (this.is_data_ready && this.sportNewsDetail)
            {
                return {
                    '@context': 'https://schema.org',
                    '@type': "NewsArticle",
                    mainEntityOfPage: {
                        '@type': 'WebPage',
                        '@id': this.$siteURL + (this.$route.path.replace(/^\/|\/$/g,''))
                    },
                    headline: this.sportNewsDetail.headline,
                    description: this.sportNewsDetail.description,
                    image: this.sportNewsDetail.image.image_path,
                    author: {
                        '@type': 'Person',
                        name: this.sportNewsDetail.admin.display_name,
                        "url": this.$siteURL
                    },
                    publisher: {
                        '@type': 'Organization',
                        name: "SABC",
                        logo: {
                            '@type': 'ImageObject',
                            url: this.$siteURL + 'public/images/logo.png'
                        }
                    },
                    datePublished: this.sportNewsDetail.created_at,
                    dateModified: this.sportNewsDetail.created_at,
                    "@context": "http://schema.org",
                    "@graph": []
                }
            }
        },
        metaInfo() {
            if (this.sportNewsDetail) {

                var ss = this.$store.state.siteSetting;
                var replacements = {
                    "%NEWS_HEADLINE%": this.sportNewsDetail.headline,
                    "%NEWS_STAND_FIRST%": this.sportNewsDetail.stand_first,
                    "%SPORT%": (this.$route.params.sport == undefined)?'SABC':this.$route.params.sport,
                    "%META_KEYWORDS%": this.keywords,
                };

                let title = this.$getSafe(() => this.$replacePlaceHolders(ss.news_detail_title,replacements));
                let description = this.$getSafe(() => this.$replacePlaceHolders(ss.news_detail_meta_description,replacements));
                let keywords = this.$getSafe(() => this.$replacePlaceHolders(ss.news_detail_meta_keywords,replacements));
                let image_path = this.$resizeImage(this.sportNewsDetail.image.image_path, 1600, 900);

                let metaProperty = [{ property: "article:publisher", content: "https://www.facebook.com/SABCSport/"}];
                keywords.split(",").forEach(function(item) {
                    if(item){
                        metaProperty.push({ property: "article:tag", content: item});
                    }
                });
                metaProperty.push({property: "article:section",content: "All The News"});
                metaProperty.push({property: "article:published_time",content: moment(this.sportNewsDetail.start_date).format('YYYY-MM-DDTHH:mm:ssZ')});
                metaProperty.push({property: "article:modified_time",content: moment(this.sportNewsDetail.updated_at).format('YYYY-MM-DDTHH:mm:ssZ')});
                metaProperty.push({property: "og:updated_time",content: moment(this.sportNewsDetail.updated_at).format('YYYY-MM-DDTHH:mm:ssZ')});
                metaProperty.push({property: "og:image:secure_url",content: image_path});
                metaProperty.push({property: "og:image:width",content: "1600"});
                metaProperty.push({property: "og:image:height",content: "900"});
                metaProperty.push({property: "twitter:creator",content: this.sportNewsDetail.admin.display_name});

                return {
                    title: title,
                    description: description,
                    meta: this.$getMetaTags(title, description, keywords, image_path, metaProperty),
                    link: [
                        {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}
                    ],
                }
            }
        },

        methods: {
            moment: function () {
                return moment();
            },

            getNewsDetail() {
                let keywords = [];
                this.is_data_ready = false;
                return this.$axios.get(this.$GetSportsNewsDetail + '?slug=' + this.$route.params.slug)
                    .then(response => {
                        if (response.data) {
                            this.sportNewsDetail = response.data.news;
                            this.relatedArticles = response.data.relatedNews;
                            if (this.relatedArticles.length == 0) {
                                this.relatedArticles = null;
                            }
                            this.editorsPick = response.data.editorsPick;
                            this.is_data_ready = true;
                            //--------------------------------------------
                        }
                    })
                    .catch(error => {
                    })
            },
        }
    }

</script>
<style lang="scss">
@import '~@/assets/scss/right_sidebar.scss';
@import '~@/assets/scss/article_detail.scss';
</style>
